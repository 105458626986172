import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/analytics';

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//DEV
const firebaseConfig = {
  apiKey: "AIzaSyAZbFopWhDB09w7NlFsvBlwSfafRkGZcuQ",
  authDomain: "orange-fig.firebaseapp.com",
  databaseURL: "https://orange-fig.firebaseio.com",
  projectId: "orange-fig",
  storageBucket: "orange-fig.appspot.com",
  messagingSenderId: "962077603281",
  appId: "1:962077603281:web:145071cce199f58bc95cf0",
  measurementId: "G-P0ZHBJSSQ4"
};

//QA
// const firebaseConfig = {
//   apiKey: "AIzaSyAZz22G5gQd_xEyhw_bH_cOm0S9KRvK9gs",
//   authDomain: "orangefig-dev.firebaseapp.com",
//   projectId: "orangefig-dev",
//   storageBucket: "orangefig-dev.appspot.com",
//   messagingSenderId: "44584418066",
//   appId: "1:44584418066:web:e3c7c30cc36fe5d37a51cb",
//   measurementId: "G-V2ZNSNK7BS"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

const firebaseIns = firebase.initializeApp(firebaseConfig);

/*
  Used functions.
 */
const firestore = firebaseIns.firestore();
const auth = firebaseIns.auth();
const storage = firebaseIns.storage();
const firebaseFunctions = firebaseIns.functions();
const firebaseAnalytics = firebaseIns.analytics();

export { firestore, firebaseFunctions, auth, storage, firebaseAnalytics };