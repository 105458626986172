import React, { useEffect, useState } from 'react';
import './index.css';
import { debounce, validateEmail, validateMobile } from '../../utils';
import { sendEmail, freshworksSaveContact } from '../../operations/common';
import { GenericErrorForm, EnterValidEmail, EnterValidMobile } from '../../operations/utils';
import { updateLeadsData } from '../../operations/onboarding';
import { fetchProducts, fetchSummerCamps } from '../../operations/products';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import { useDispatch } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';
import ProductsCarousel from "./Carousel"


import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { firebaseAnalytics } from '../../firebase';

const
    SummerCamp = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('Thankyou for sharing your data.');
  const [showSuccess, setShowSuccess] = useState(false);
  const [products, setProducts] = useState([]);
  const [summercamps, setSummerCamp] = useState([]);
  const [availableProducts,setavailableProducts]= useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const totalPrice = summercamps && summercamps.length > 0 && summercamps[0].price ? summercamps[0].price : 0;
  const discount = summercamps &&  summercamps.length > 0 && summercamps[0].discount_price ? summercamps[0].discount_price : 0;
  const kitData = summercamps &&  summercamps.length > 0  ? summercamps[0] : {};
  const productUrl = products && products.length > 0 ? products[0] : 'https://firebasestorage.googleapis.com/v0/b/orange-fig.appspot.com/o/recipe_videos%2FWhatsApp%20Video%202021-04-07%20at%208.10.31%20PM.mp4?alt=media&token=bc7bb963-5c2a-45d4-b2f9-916621f693aa'
  const discountPrice = totalPrice - discount;

  const getProducts = async () => {
    const result = await fetchProducts();
    const availableProductUrls = await result.filter(
        (item) =>  item.availability === "true"
    )
    .map(function(obj) {
        return obj.recipeVideoUrl;
    });

    const availableProductImages = await result.filter(
        (item) =>  item.availability === "false"
    )
    .map(function(obj) {
      
        var image = obj.image;
        var name = obj.name;
        var description = obj.description
        return {image,name,description}
    }); 
   
    setavailableProducts(availableProductImages);
    setProducts(availableProductUrls);
  };
  const getsummercamplist = async () => {
    const result = await fetchSummerCamps();
    setSummerCamp(result);
  };
  
  useEffect(() => {
    async function fetchData() {
      await getProducts();
    }
    fetchData();
    async function fetchSummercamp() {
        await getsummercamplist();
      }
      fetchSummercamp();
  }, []);

useEffect(() => {
    firebaseAnalytics.logEvent("Summer Camp");
}, []);




  const showEmailError = debounce((email) => {
    if (email === '') {
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, 1000);

  const triggerEmail = async () => {
    if (name === '' || email === '' || mobile === '') {
      GenericErrorForm();
      return;
    } else if (!validateEmail(email)) {
      EnterValidEmail();
      return;
    }
    else if (!validateMobile(mobile)) {
        EnterValidMobile();
        return;
    }
    var uid = new Date().getTime().toString();
    var createdDate = moment().format('DD-MM-YYYY HH:MM:SS');
    await updateLeadsData(uid, {
        name,
        email,
        mobile,
        createdDate
    });
    var data = 
	{
        "contact": {
            "first_name": name,
            "last_name": '',
            "email":email,
            "mobile_number": mobile,
            "country":"USA"
        }
    };
    await freshworksSaveContact(data);
    
    await sendEmail(
      `New Lead`,
      `Email: ${email} \nName: ${name} \nMobile: ${mobile}`,
      'hello@orangefigs.com'
    );

    setShowSuccess(true);
    resetFromData();
  };
  const resetFromData = () => {
    setMobile('');
    setName('');
    setEmail('');
  }



const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.SUMMER_CAMP }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
};

    return (
        <div>
            <section className="sectionOne">
                <div className="container-fluid nopadding banner-image" style={{backgroundImage: `uri('../../assets/images/summarCamp/FigsBackground.png')`}}>
                </div>

                <div className="sectionTwo">
                    <div>
                        <h4>Enter YOUR details below</h4>
                        <div id="contact-form">
                            <div className="row col-md-12 col-sm-12">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            <input name="name" type="text" placeholder="Name*" onChange={(event) => setName(event.target.value)} value={name}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            <input type="tel" name="contact" placeholder="Contact No*" onChange={(event) => setMobile(event.target.value)} value={mobile}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-9">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            <input type="email" name="email" placeholder="Email*"
                                             onChange={(event) => { setEmail(event.target.value);
                                                showEmailError(event.target.value);}
                                            }   
                                            value={email}  
                                             />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-input mt-25">
                                        <button onClick={() => triggerEmail()}>Call Me</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectionThree">
                <div>
                    {/* <h5>Oh Yes!! You’re Going to Definitely Bake it!!</h5> */}
                    <h2>Hey Kids!! We’re Bringing Summer Camp To Your Home!!!</h2>
                    <p>It’s The Tastiest Hastiest Camp You’ll Ever Attend! Learn How To Make And Bake Your Favourite Drool Worthy Desserts Of All Time Right At Home!
                     Follow Easy Peasy Instructions On Video And Enjoy Tasty Toothsome Totally Delicious Desserts!</p>
                </div>
            </section>
       
          <section className="sectionFour">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/easytodo.png")} alt="Easy To Do" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">Easy to Do</h5>
                                <p>Step by step recipe card, and Video instruction by Chefs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/noWastage.png")} alt="" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">No Wastage</h5>
                                <p>Everything you need is perfectly measured and safely packed..</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/homeDIY.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">DIY - At Home</h5>
                                <p>No need to travel in the heat (or worry about the virus).</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/pocketFriendly.png")} alt="" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">Pocket Friendly</h5>
                                <p>While you learn - you also get to taste treats that will cost more otherwise. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ProductsCarousel data= {availableProducts}/>

            <section id="testimonial" className="sectionFive">
                <div className="containeDiv">
                    <div className="row d-flex">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                            <div className="testimonial-left-content mt-45">
                                <h6 className="sub-title">Hear out</h6>
                                <h4 className="title">Watch Young Bakers Share Their Experience.</h4>
                                <p className="text">Watch young bakers and Jr. Chefs like you share their Orange Figs Unboxing Experiences!  (And we can’t wait to showcase what you have baked with the rest of the gang!)</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* <img src={require("../../assets/images/summarCamp/testimonial.png")} /> */}
                            <div className="videoComponent">
                            <video width="100%" height="100%" muted controls poster={require("../../assets/images/summarCamp/testimonial.png")}>
                                    <source src={productUrl} />
                                </video>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="" className="sectionSix">
                <img src={require("../../assets/images/summarCamp/sectionBG.png" )} className="section-background"/>
                <div className="containeDiv">
                    <div className="row d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <img src={require("../../assets/images/summarCamp/Mask Group.png")} alt="mask-group" />
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                            <div className="testimonial-left-content mt-45">
                                <h4 className="title">Get Your Hands On Our Summer Camp Kits</h4>
                                <p className="text">It’s fun, It’s yum - and it’s totally out of the box!</p>
                            </div>
                            <div className="price"><del>{`Rs.${totalPrice}/-`} </del></div>
                            <div className="price">{`Rs.${discountPrice}/-`}</div>
                            <button onClick={() => addToCart(kitData)}>Buy Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sectionSeven">
                <h5>Here’s What you get with Orange Figs At-Home Summer Camp!</h5>
                <div className="row col-md-12 justify-content-center">
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/bakingkit.png")} alt="Easy To Do" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Pack of 12 DIY Baking kits delivered safely to your doorstep</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/videos.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Free Orange Figs App With Video lessons and much more</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/goodies.png")} alt="goodies" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Great learning at home And goodies you can share and show-off!</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sectionEight">
                <div className="BGTop" >
                    <img src={require("../../assets/images/summarCamp/Bg-top.png")} alt="bg-top" />
                </div>
                <div className="BGBottom">
                    <img src={require("../../assets/images/summarCamp/Bg-bottom.png")} alt="bg-bottm"/></div>


                <div className="content-left">
                    <img src={require("../../assets/images/summarCamp/MobileFrame.png")} alt="mobile"/>
                </div>
                <div className="content-right">
                    <h6 className="sub-title">Download App</h6>
                    <h4 className="title">Download the Orange Figs App For Free!</h4>
                    <p>An app that provides a perfect blend of engaging video recipes by best bakers across the globe and DO IT YOURSELF kit to kid’s door step.</p>
                    <div className="d-flex mtop-30">
                        <a href="https://play.google.com/store/apps/details?id=com.orangefigs" target="_blank">
                            <img src={require("../../assets/images/summarCamp/Google.png")} alt="google"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/orange-figs/id1555526421" target="_blank">
                            <img src={require("../../assets/images/summarCamp/Apple Store.png")} alt="app-store"/>
                        </a>
                    </div>
                </div>
            </section>
            <section className="footer">
                <p>Copyright ©2021 OrangeFigs     All Rights Reserved</p>
                <ul>
                <a onClick= {()=> history.push('/faq')}><li>FAQ</li></a>
                <a onClick= {()=>history.push('/returnPolicy')}><li>Return policy</li></a>
                <a onClick= {()=>history.push('/aboutUs')}><li>About us</li></a>                           
                <a onClick= {()=>history.push('/privacyPolicy')}><li>Privacy Policy</li></a>
                <a onClick= {()=>history.push('/termsOfUse')}><li>Terms of Use</li></a>
                </ul>
            </section>
        </div>
    );
};

export default SummerCamp;
