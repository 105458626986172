import React, { useEffect, useState } from 'react';
import './index.css';
import {  debounce, validateEmail, validateMobile } from '../../utils';
import { sendEmail, freshworksSaveContact } from '../../operations/common';
import { GenericErrorForm, EnterValidEmail, EnterValidMobile } from '../../operations/utils';
import { updateLeadsData } from '../../operations/onboarding';
import { fetchProducts, fetchSummerCamps } from '../../operations/products';
import CartActions from '../../redux/reducer/cartReducer';
import { PRODUCT_TYPE } from '../../utils';
import { useDispatch } from 'react-redux';
import GlobalActions from '../../redux/reducer/globalReducer';
import ProductsCarousel from "./Carousel"
import { makeStyles } from '@material-ui/core/styles';


import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { firebaseAnalytics } from '../../firebase';
import ContactUs from './ContactUs';
import {Paths} from './../../routes/routePaths'
// import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';

const useStyles = makeStyles((theme) => ({
    notchedOutline: {
      borderWidth: '1px',
      borderColor: '#C4C4C4 !important',
      borderRadius: '8px',
      marginBottom:'20px'
    },
    input: {
      color: '#6B6B6B',
      fontFamily: 'Andes',
    }
  }));
const SummerCamp = (props) => {

//   Paths.SummerCamp = Paths.SummerCampUSA;
//   currencySymbol.currency = '$';
//   currencySymbol.country = 'usa';
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('Thankyou for sharing your data.');
  const [showSuccess, setShowSuccess] = useState(false);
  const [products, setProducts] = useState([]);
  const [summercamps, setSummerCamp] = useState([]);
  const [availableProducts,setavailableProducts]= useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneError, setphoneError] = useState("");
//   let cart = useSelector((state) => state.cart);
//   console.log("cart data--",cart)
  const summercampsData = summercamps.filter(
    (item) =>  item.availability == true
 );
  const totalPrice = summercampsData && summercampsData.length > 0 && summercampsData[0].price ? summercampsData[0].price : 0;
  const discount = summercampsData &&  summercampsData.length > 0 && summercampsData[0].discount_price ? summercampsData[0].discount_price : 0;
  const kitData = summercampsData &&  summercampsData.length > 0  ? summercampsData[0] : {};
  const productUrl = products && products.length > 0 ? products[0] : 'https://firebasestorage.googleapis.com/v0/b/orange-fig.appspot.com/o/recipe_videos%2FWhatsApp%20Video%202021-04-07%20at%208.10.31%20PM.mp4?alt=media&token=bc7bb963-5c2a-45d4-b2f9-916621f693aa'
  const discountPrice = totalPrice - discount;

  const getProducts = async () => {
    const result = await fetchProducts();
    const availableProductUrls = await result
    // .filter(
    //     (item) =>  item.availability === "true"
    // )
    .map(function(obj) {
        return obj.recipeVideoUrl;
    });

    const items = ['Carrot Turmeric Cake','Ginger Cookie House','Boston Brownie'];
    const availableProductImages = await result
    .filter(
        (item) =>  items.includes(item.name)
    )
    .map(function(obj) {
      
        var image = obj.image;
        var name = obj.name;
        var description = obj.description
        return {image,name,description}
    }); 
   
    setavailableProducts(availableProductImages);
    setProducts(availableProductUrls);
  };
  const getsummercamplist = async () => {
    const result = await fetchSummerCamps();
    setSummerCamp(result);
  };
  
  useEffect(() => {
    async function fetchData() {
      await getProducts();
    }
    fetchData();
    async function fetchSummercamp() {
        await getsummercamplist();
      }
      fetchSummercamp();
  }, []);

useEffect(() => {
    firebaseAnalytics.logEvent("Summer Camp");
}, []);




  const showEmailError = debounce((email) => {
    if (email === '') {
      return;
    }
    if (!validateEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }, 1000);

  const triggerEmail = async () => {
    if (name === '' || email === '' || mobile === '') {
      GenericErrorForm();
      return;
    } else if (!validateEmail(email)) {
      EnterValidEmail();
      return;
    }
    else if (!(mobile)) {
        EnterValidMobile();
        return;
    }
    var uid = new Date().getTime().toString();
    var createdDate = moment().format('DD-MM-YYYY HH:MM:SS');
    await updateLeadsData(uid, {
        name,
        email,
        mobile,
        country:'USA',
        createdDate
    });
    var data = 
	{
        "contact": {
            "first_name": name,
            "last_name": '',
            "email":email,
            "mobile_number": mobile,
            "country":"USA"
        }
    };
    await freshworksSaveContact(data);
    
    await sendEmail(
      `New Lead`,
      `Email: ${email} \nName: ${name} \nMobile: +${mobile}\n country:USA`,
      'hello@orangefigs.com'
    );

    setShowSuccess(true);
    resetFromData();
  };
  const resetFromData = () => {
    setMobile('');
    setName('');
    setEmail('');
  }



const addToCart = (item) => {
    dispatch(CartActions.addToCart({ ...item, type: PRODUCT_TYPE.SUMMER_CAMP }));
    dispatch({ type: GlobalActions.setCartStatus.SUCCESS, payload: true });
    dispatch(CartActions.setCounrty.success({country:'USA',currencySymbol:'$',homePath:Paths.SummerCampUSA }));
};

    return (
        <div>
            <section className="sectionOne">
                <div className="text-center">
                    <img src={require('../../assets/images/summerCampUSA/orangefigs-banner-image-usa.png')} alt="Home DIY" />
                </div>

                <div className="sectionTwo">
                    <div>
                        <h4>Enter YOUR details below</h4>
                        <div id="contact-form">
                            <div className="row col-md-12 col-sm-12">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            <input name="name" type="text" placeholder="Name*" onChange={(event) => setName(event.target.value)} value={name}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            {/* <input type="tel" name="contact" placeholder="Contact No*" onChange={(event) => setMobile(event.target.value)} value={mobile}/> */}
                                            <PhoneInput
                                                className="inputData"
                                                country={ 'us'}
                                                // value={guestNumber}
                                                enableSearch = {true}
                                                placeholder={"Enter Phone Number"}
                                                required={true} 
                                                autoFocus= {true}
                                                onChange={(event) => {
                                                    setMobile(event);
                                                }}
                                                InputProps={{
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                    input: classes.input,
                                                },
                                                }}
                                                error={phoneError}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-sm-9">
                                    <div className="form-input mt-25">

                                        <div className="input-items default">
                                            <input type="email" name="email" placeholder="Email*"
                                             onChange={(event) => { setEmail(event.target.value);
                                                showEmailError(event.target.value);}
                                            }   
                                            value={email}  
                                             />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-3">
                                    <div className="form-input mt-25">
                                        <button onClick={() => triggerEmail()}>Call Me</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectionThree">
                <div>
                    <h5>TAKE A BAKE BREAK!</h5>
                    <h2>Bring Home the Joy of Baking.</h2>
                    <p>Unbox the healthiest, tastiest DIY recipes that are sure to give you a rumble in your belly!
                    A learning and bonding experience for your Kids dressed as the fun of baking, wrapped in a box.
                    </p>
                </div>
            </section>
       
          <section className="sectionFour">
          <div className="row justify-content-center">
          <h2 className="sub-title" style={{color: '#b52a65',marginBottom:'30px'}}>New recipes delivered at your doorstep each month.</h2>
          </div>
                <div className="row justify-content-center">
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/easytodo.png")} alt="Easy To Do" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">Easy-to-follow</h5>
                                <p>Step by step kid-friendly exciting recipe cards, and video instructions by Chefs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/noWastage.png")} alt="" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">Zero-Wastage</h5>
                                <p>Pour, mix and bake with pre-measured ingredients and safely packed kit.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/homeDIY.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">DIY-at-Home</h5>
                                <p>Spend time with your loved one while also enjoying freshly homemade healthy treats.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/pocketFriendly.png")} alt="" />
                            </div>
                            <div className="pricing-header text-center">
                                <h5 className="sub-title">Pocket-friendly</h5>
                                <p>Baking, learning and treating all-in-one experience at a budget-friendly subscription fee. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ProductsCarousel data= {availableProducts} slider={false}/>

            {/* <section id="testimonial" className="sectionFive">
                <div className="containeDiv">
                    <div className="row d-flex">
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                            <div className="testimonial-left-content mt-45">
                                <h6 className="sub-title">Hear out</h6>
                                <h4 className="title">Watch Young Bakers Share Their Experience.</h4>
                                <p className="text">Watch young bakers and Jr. Chefs like you share their Orange Figs Unboxing Experiences!  (And we can’t wait to showcase what you have baked with the rest of the gang!)</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="videoComponent">
                            <video width="100%" height="100%" muted controls poster={require("../../assets/images/summarCamp/testimonial.png")}>
                                    <source src={productUrl} />
                                </video>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

            <section id="" className="sectionSix">
                {/* <img src={require("../../assets/images/summarCamp/sectionBG.png" )} className="section-background"/> */}
                <div className="containeDiv">
                    <div className="row d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <img src={require("../../assets/images/summerCampUSA/product.JPG")} alt="mask-group" style={{borderRadius:30}}/>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                            <div className="testimonial-left-content mt-45">
                                <h4 className="title">Grab an Orange Figs Box NOW!</h4>
                                <p className="text">It’s fun, It’s yum - and it’s totally out of the box!</p>
                            </div>
                            {/* <div className="price"><del>{`Rs.${totalPrice}/-`} </del></div> */}
                            {/* <div className="price">{`Rs.${discountPrice}/-`}</div> */}

                            <div className="price">{`$${totalPrice}`}</div> 
                            <label className="price_label">(Inclusive of 3 Recipe kits, 1 Chef Apron, 1 Chef Hat, 1 Cookie Cutter)</label>
                            <button onClick={() => addToCart(kitData)}>Buy Now</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sectionSeven">
                <h4>Know your Orange Figs DIY kit</h4>
                <div className="row col-md-12 justify-content-center">
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="pricing-icon text-center">
                                <img src={require("../../assets/images/summarCamp/bakingkit.png")} alt="Easy To Do" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Pack of 3 DIY Recipe Kits delivered safely to your doorstep</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/videos.png")} alt="Home DIY" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Free Orange Figs App With Video lessons and much more</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="pricing-style mt-30">
                            <div className="text-center">
                                <img src={require("../../assets/images/summarCamp/goodies.png")} alt="goodies" />
                            </div>
                            <div className="pricing-header text-center">
                                <h6 className="sub-title">Delicious & Healthy treats for all, baked at home with love</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactUs country="USA"/>

            <section className="sectionEight">
                <div className="BGTop" >
                    <img src={require("../../assets/images/summarCamp/Bg-top.png")} alt="bg-top" />
                </div>
                <div className="BGBottom">
                    <img src={require("../../assets/images/summarCamp/Bg-bottom.png")} alt="bg-bottm"/></div>


                <div className="content-left">
                    <img src={require("../../assets/images/summarCamp/MobileFrame.png")} alt="mobile"/>
                </div>
                <div className="content-right">
                    {/* <h6 className="sub-title">Download App</h6> */}
                    <h4 className="title">Download the Orange Figs App For Free!</h4>
                    <p style={{color: '#6B6B6B'}}>Baking just got easier!</p>
                    <p>Perfect blend of engaging video recipes by best bakers across the globe and much more on your fingertips, available at Orange Figs app!</p>
                    <div className="d-flex mtop-30">
                        <a href="https://play.google.com/store/apps/details?id=com.orangefigs" target="_blank">
                            <img src={require("../../assets/images/summarCamp/Google.png")} alt="google"/>
                        </a>
                        <a href="https://apps.apple.com/us/app/orange-figs/id1555526421" target="_blank">
                            <img src={require("../../assets/images/summarCamp/Apple Store.png")} alt="app-store"/>
                        </a>
                    </div>
                </div>
            </section>
            <section className="footer">
                <p>Copyright ©2021 Orange Figs     All Rights Reserved</p>
                {/* <ul>
                <a onClick= {()=> history.push('/faq')}><li>FAQ</li></a>
                <a onClick= {()=>history.push('/returnPolicy')}><li>Return policy</li></a>
                <a onClick= {()=>history.push('/aboutUs')}><li>About us</li></a>                           
                <a onClick= {()=>history.push('/privacyPolicy')}><li>Privacy Policy</li></a>
                <a onClick= {()=>history.push('/termsOfUse')}><li>Terms of Use</li></a>
                </ul> */}
            </section>
        </div>
    );
};

export default SummerCamp;
